<template>
  <sub-page
      :title="$t('Settings')"
      v-model="$store.state.settings.pageData"
  >

    <template #default>
      <ws-accordion
          class="mt-5"
          :items="settingsSelect"
      >
        <template #action.document_types="{expanded}">
          <ws-button @click="openNewType" v-if="expanded" class="mr-5" label="Add"></ws-button>
        </template>

        <template #item.document_types>
          <ws-data-table
              :items="documentTypes"
              :headers="headers"
          >
            <template #item.action="{item,hover}">
              <div>
                <v-btn icon :color="wsACCENT" @click="openEditType(item)" >
                  <v-icon v-if="hover">mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </ws-data-table>
        </template>
      </ws-accordion>
      <ws-accordion
          class="mt-5"
          :items="navigationSelect"
          @select="handleSelect"
      >
        <template #item="{item,element}">

          <div     v-if="getStructureEntityDocumentTypes(item.uuid).length > 0">
            <ws-data-table

                :items="getStructureEntityDocumentTypes(item.uuid)"
                :headers="headers"
                no-header
                no-footer
                disable-pagination
                class="mb-n7"
            >
              <template #item.action="{item,hover}">
                <div>
                  <v-btn icon :color="wsACCENT" @click="openEditStructureType(item , element.uuid)" >
                    <v-icon v-if="hover">mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </template>
            </ws-data-table>
            <ws-button @click="openNewStructureType(item.uuid)" icon="mdi-plus" label="Add" text block />
          </div>
          <div v-else  class="d-flex align-center justify-center py-10 ">
            <div>
              <div class="d-flex justify-center">
                <v-icon size="60" :color="wsDARKLIGHT">mdi-file-document-edit-outline</v-icon>
              </div>
              <h4 class="text-center mt-3">
                {{ $t('documentflow.settings.type.no_data') }}
              </h4>
              <div class="d-flex justify-center mt-3">
                <ws-button @click="openNewStructureType(item.uuid)"  label="Add"  />
              </div>
            </div>
          </div>
        </template>

      </ws-accordion>
    </template>

    <template #dialog>
      <!-- Document Type Dialog-->
      <ws-dialog
          v-if="displayTypeDialog"
          @save="addDocumentType"
          @delete="deleteDocumentType"
          v-model="displayTypeDialog"
          :title="$t(newTypeEntity ? 'documentflow.settings.type.add' : 'documentflow.settings.type.edit')"
          :show-delete="!newTypeEntity"
          :save-text="newTypeEntity ? $t('Add') : $t('Save')"
      >

        <ws-text-field
            v-if="!editCategoryType"
            v-model="typeEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />
        <ws-select
            v-else
            @input="handleDocumentTypeChange"
            v-model="typeEntityData.document_type_id"
            :items="documentTypesSelect"
            :label="$t('DocumentType')"
            :placeholder="$t('ChooseDocumentType')"
            :disabled="!newTypeEntity"
        />
        <div class="d-flex justify-space-between align-center mt-5">
          <h4 class="font-weight-regular " >
            {{ $t('DefaultValues') }}:
          </h4>
          <ws-tooltip :text="$t('documentflow.settings.default_values_tooltip')">
            <v-icon :color="wsACCENT">mdi-information-outline</v-icon>
          </ws-tooltip>
        </div>

        <!-- Signators -->
        <div class="mt-5">
          <h5 :style="`color : ${wsACCENT}`" class="mb-3 font-weight-regular">{{ $t('Signators') }}</h5>
          <v-sheet   color="transparent"  :set="!user ? user=[] : null" :style="`border : 1px solid ${wsDARKLIGHT} !important`" class="wsRoundedLight">

            <v-sheet
                v-for="(signator,index) in typeEntityData.signators"
                :key="signator.user_id"
                color="transparent"
                class="px-3"
                min-height="50"
                height="50"
                max-height="50"
                :class="[{'mt-2' : index === 0 }]"
            >
              <v-sheet
                  :set="user[index] = getUser(signator.user_id)"
                  class="d-flex justify-space-between align-center fill-height"
                  style="width : 100%"
              >
                <div v-if="user && user[index]" class="d-flex align-center" >
                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!user[index].img" dark>mdi-account</v-icon>
                    <v-img v-else :src="user[index].img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ user[index].text }} </h5>
                    <ft-select
                        :disabled="user[index].positions_select.length < 2"
                        v-model="typeEntityData.signators[index].position_id"
                        :items="user[index].positions_select"
                        :key="index"
                    >
                      <template #default="{text}">
                        <v-sheet color="transparent" class="d-flex justify-space-between align-center shorten-text">
                          <h5 class="font-weight-regular">
                            {{ text || '' }}
                          </h5>
                          <v-icon v-if="user[index].positions_select.length > 1" :color="wsACCENT">mdi-menu-down</v-icon>
                        </v-sheet>
                      </template>

                    </ft-select>
                  </div>

                </div>
                <div class="d-flex align-center">

                  <v-btn class="ml-5" @click="removeSignator(signator.user_id)" icon small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>

              </v-sheet>

            </v-sheet>
            <v-divider v-if="typeEntityData.signators.length > 0" class="mt-3" :style="`border-color : ${wsDARKLIGHT}`" />
            <ws-select
                :key="updateTrigger"
                v-if="managersSelectFiltered.length > 0"
                @input="addSignator"
                :value="newSignator"
                :items="managersSelectFiltered"
                :placeholder="$t('documentflow.signature.choose_signator')"
                autocomplete
                :error="signatorError"
                flat solo
            >
              <template #item="{item}">

                <v-sheet width="100%" color="transparent" class="d-flex align-center" :style="`border-bottom : 1px solid ${wsBACKGROUND} !important`" height="46"  >


                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!item.img" dark>mdi-account</v-icon>
                    <v-img v-else :src="item.img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ item.text }} </h5>
                    <div class="shorten-text">
                      <h5   class="d-flex align-center font-weight-regular shorten-text"  v-if="item.positions_select && item.positions_select.length > 0">
                        {{ item.positions_select[0].text }}
                      </h5>
                    </div>

                  </div>



                </v-sheet>

              </template>
            </ws-select>

          </v-sheet>
        </div>
        <!-- Aprovals  -->
        <div>
          <h5 :style="`color : ${wsACCENT}`" class="mt-5 mb-3 font-weight-regular">{{ $t('documentflow.document.has_approval_signator') }}</h5>
          <v-sheet   color="transparent"  :set="!apruser ? apruser=[] : null" :style="`border : 1px solid ${wsDARKLIGHT} !important`" class="wsRoundedLight">

            <v-sheet
                v-for="(signator,index) in typeEntityData.aprovals"
                :key="signator.user_id"
                color="transparent"
                class="px-3"
                min-height="50"
                height="50"
                max-height="50"
                :class="[{'mt-2' : index === 0 }]"
            >
              <v-sheet
                  :set="apruser[index] = getUser(signator.user_id , true)"
                  class="d-flex justify-space-between align-center "
                  style="width : 100%"
              >
                <div v-if="apruser && apruser[index]" class="d-flex align-center" >
                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!apruser[index].img" dark>mdi-account</v-icon>
                    <v-img v-else :src="apruser[index].img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ apruser[index].text }} </h5>
                    <ft-select
                        :disabled="apruser[index].positions_select.length < 2"
                        v-model="typeEntityData.aprovals[index].position_id"
                        :items="apruser[index].positions_select"
                        :key="index"
                    >
                      <template #default="{text}">
                        <v-sheet color="transparent" class="d-flex justify-space-between align-center shorten-text">
                          <h5 class="font-weight-regular">
                            {{ text || '' }}
                          </h5>
                          <v-icon v-if="apruser[index].positions_select.length > 1" :color="wsACCENT">mdi-menu-down</v-icon>
                        </v-sheet>
                      </template>

                    </ft-select>
                  </div>

                </div>
                <div class="d-flex align-center">

                  <v-btn class="ml-5" @click="removeSignator(signator.user_id , true)" icon small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>

              </v-sheet>

            </v-sheet>
            <v-divider v-if="typeEntityData.aprovals.length > 0" class="mt-3" :style="`border-color : ${wsDARKLIGHT}`" />
            <ws-select
                :key="updateTrigger"
                v-if="managersSelectFiltered.length > 0"
                @input="addSignator($event, true)"
                :value="newSignator"
                :items="managersSelectFiltered"
                :placeholder="$t('documentflow.signature.choose_signator')"
                autocomplete
                :error="signatorError"
                flat solo
            >
              <template #item="{item}">

                <v-sheet width="100%" color="transparent" class="d-flex align-center" :style="`border-bottom : 1px solid ${wsBACKGROUND} !important`" height="46"  >
                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!item.img" dark>mdi-account</v-icon>
                    <v-img v-else :src="item.img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ item.text }} </h5>
                    <div class="shorten-text">
                      <h5   class="d-flex align-center font-weight-regular shorten-text"  v-if="item.positions_select && item.positions_select.length > 0">
                        {{ item.positions_select[0].text }}
                      </h5>
                    </div>
                  </div>

                </v-sheet>

              </template>
            </ws-select>

          </v-sheet>

        </div>


      </ws-dialog>

    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Tasks",
  data() {
    return {
      items : [],
      displayTypeDialog : false,
      categoryEntityData : {},
      typeEntityData : {},
      newTypeEntity : false,
      documentTypes : [],

      displayStructureDialog : false,
      newStructureTypeEntity : false,

      managersSelect : [],
      newSignator : null,
      updateTrigger : 0,
      signatorError : false,

      editCategoryType : false,

    }
  },
  computed : {
    managersSelectFiltered() {
      let items = this.COPY(this.managersSelect)
      if ( !this.typeEntityData.signators ) {
        return items
      }
      return items.filter(el => !(this.typeEntityData.signators.map(el => el.user_id).includes(el.value) || this.typeEntityData.aprovals.map(el => el.user_id).includes(el.value) )|| this.newSignator === el.value)
    },
    documentTypesSelect() {
      let items = []
      this.documentTypes.forEach(el => {
        items.push({
          text : el.name,
          value : el.uuid
        })
      })
      return items
    },
    settingsSelect() {
      return [
        { name : this.$t('documentflow.document.types') , value : 'document_types' , subtitle : this.$t('documentflow.document.types_description'), icon : 'mdi-file-outline' , expanded : true },
      ]
    },
    navigationSelect() {
      return this.items
    },
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { value : 'action' , width : 10 }
      ]
    }
  },

  methods : {
    ...mapActions('documentflow', [
      'GET_DOCUMENTS_SETTINGS',
      'ADD_EDIT_DOCUMENTS_CATEGORY',
      'DELETE_DOCUMENT_CATEGORY',
      'ADD_EDIT_DOCUMENT_TYPE',
      'DELETE_DOCUMENT_TYPE',
      'ADD_EDIT_DOCUMENT_TYPE_DATA',
      'DELETE_DOCUMENT_TYPE_DATA',
    ]),

    handleDocumentTypeChange(value) {
      let documentType = this.documentTypes.find(el => el.uuid === value)
      if ( documentType &&  documentType.document_type_data && documentType.document_type_data.length > 0) {
        let documentData = documentType.document_type_data.find(el => !el.structure_entity_id )
        if ( documentData ) {
          this.typeEntityData.signators = documentData.signators
          this.typeEntityData.aprovals = documentData.aprovals
          this.typeEntityData = this.COPY(this.typeEntityData)
        }
      }
    },

    async addStructureDocumentType() {
      let result = await this.ADD_EDIT_DOCUMENT_TYPE_DATA(this.typeEntityData)
      if ( !result ) {
        return this.ERROR()
      }

      let index = this.documentTypes.findIndex(el => el.uuid === result.uuid)
      this.documentTypes[index] = result
      this.documentTypes = this.COPY(this.documentTypes)

      this.displayTypeDialog = false
    },
    async deleteStructureDocumentType() {

      let result = await this.DELETE_DOCUMENT_TYPE_DATA(this.typeEntityData)
      if ( !result ) {
        return this.ERROR()
      }
      let index = this.documentTypes.findIndex(el => el.uuid === this.typeEntityData.uuid)
      if ( index === -1 ) {
        this.ERROR()
        return
      }
      this.documentTypes[index] = result
      this.documentTypes = this.COPY(this.documentTypes)
      this.displayTypeDialog = false
    },
    openNewStructureType(uuid) {
      this.newTypeEntity = true
      this.editCategoryType = true
      this.newStructureTypeEntity = true
      this.typeEntityData = {
        structure_entity_id : uuid,
        signators : [],
        aprovals : []
      }
      this.displayTypeDialog = true
    },
    openEditStructureType(item , structureUuid) {
      this.newTypeEntity = false
      this.editCategoryType = true
      this.newStructureTypeEntity = false
      this.typeEntityData = this.COPY(item)
      this.typeEntityData.document_type_id = item.uuid
      this.typeEntityData.structure_entity_id = structureUuid
      this.typeEntityData.signators = []
      this.typeEntityData.aprovals = []

      let documentData = item.document_type_data.find(el => el.structure_entity_id === structureUuid)
      if ( documentData ) {
        this.typeEntityData.signators = documentData.signators
        this.typeEntityData.aprovals = documentData.aprovals
        this.typeEntityData = this.COPY(this.typeEntityData)
      }

      this.displayTypeDialog = true
    },

    handleSelect(value) {
      let index = this.items.findIndex(el => el.value === value)
      if ( !this.items[index].expanded  ) {
        this.items[index].expanded = true
      } else {
        this.items[index].expanded = false
      }
    },

    openNewType() {
      this.editCategoryType = false
      this.newTypeEntity = true
      this.typeEntityData = {
        signators : [],
        aprovals : []
      }
      this.displayTypeDialog = true
    },
    openEditType(item) {
      this.editCategoryType = false
      this.typeEntityData = this.COPY(item)
      this.typeEntityData.signators = []
      this.typeEntityData.aprovals = []
      let documentData = item.document_type_data.find(el => !el.structure_entity_id)
      if ( documentData ) {
        this.typeEntityData.signators = documentData.signators
        this.typeEntityData.aprovals = documentData.aprovals
        this.typeEntityData = this.COPY(this.typeEntityData)
      }
      this.newTypeEntity = false
      this.displayTypeDialog = true
    },

    async addDocumentType() {

      if ( this.editCategoryType ) {
        return this.addStructureDocumentType()
      }

      let result = await this.ADD_EDIT_DOCUMENT_TYPE(this.typeEntityData)
      if ( !result ) {
        return this.ERROR()
      }

      if ( this.newTypeEntity ) {
        this.documentTypes.push(result)
      } else {
        let index = this.documentTypes.findIndex(el => el.uuid === result.uuid)
        this.documentTypes[index] = result
        this.documentTypes = this.COPY(this.documentTypes)
      }

      this.displayTypeDialog = false
    },
    async deleteDocumentType() {

      if ( this.editCategoryType ) {
        return this.deleteStructureDocumentType()
      }

      let result = await this.DELETE_DOCUMENT_TYPE(this.typeEntityData.uuid)
      if ( !result ) {
        return this.ERROR()
      }
      let index = this.documentTypes.findIndex(el => el.uuid === this.typeEntityData.uuid)
      if ( index === -1 ) {
        this.ERROR()
        return
      }
      this.documentTypes.splice(index , 1)
      this.displayTypeDialog = false
    },

    //technical
    getStructureEntityDocumentTypes(uuid) {
      return this.documentTypes.filter(el => el.document_type_data.filter(item => item.structure_entity_id === uuid).length > 0)
    },

    removeSignator(value , isAproval) {
      this.updateTrigger++
      if ( !isAproval ) {
        this.typeEntityData.signators = this.typeEntityData.signators.filter(el => el.user_id !== value)
      } else {
        this.typeEntityData.aprovals = this.typeEntityData.aprovals.filter(el => el.user_id !== value)
      }

    },
    addSignator(value , isAproval = false) {
      this.signatorError = null

      let user = this.getUser(value)

      let data = {
        user_id : value
      }
      if ( user.positions_select && user.positions_select.length > 0 ) {
        data.position_id = user.positions_select[0].value
      }

      if ( isAproval) {
        this.typeEntityData.aprovals.push(data)
      } else {
        this.typeEntityData.signators.push(data)
      }
      this.newSignator = null
      this.updateTrigger++
    },

    getUser(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user
      }
      return {}
    },

    async initPage() {
      let result = await this.GET_DOCUMENTS_SETTINGS()
      if ( !result ) {
        return
      }
      this.documentTypes = result.items
      this.items = result.structure_entities
      this.managersSelect = result.managers_select
    }
  },
  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>